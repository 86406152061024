/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// export const onServiceWorkerUpdateReady = () => {
//     const answer = window.confirm(
//         `This application has been updated. ` +
//         `Reload to display the latest version?`
//     )
//     if (answer === true) {
//         window.location.reload()
//     }
// }


import React from "react"
import { SiteProvider } from "./src/context/SiteContext"

export const onRouteUpdate = ({ location, prevLocation }) => {
    // console.log("new pathname", location.pathname)
    // console.log("old pathname", prevLocation ? prevLocation.pathname : null)
}

export const wrapRootElement = ({ element }) => (
    <SiteProvider>{element}</SiteProvider>
)