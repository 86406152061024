import React, { Component } from 'react'
import { graphql, StaticQuery } from "gatsby"
import { navigate } from '@reach/router'
import TextField from '@material-ui/core/TextField';
import iconSet from '../../site_icons/selection.json'
import IcomoonReact, { iconList } from 'icomoon-react'

import SiteContext from "../context/SiteContext"

const styles = {
    searchBox: {
        position: 'absolute',
        top: 76,
        left: 0,
        background: '#FFF',
    },
    menu: {
        position: 'absolute',
        top: 76,
        right: 0,
        background: '#FFF',
        padding: 15,
        boxShadow: '0 40px 20px -2px #f5f5f5',
        zIndex: 100,
        width: '100%',
    }
};

class HeaderTools extends Component {


    // TODO Fix products going to /products/ and not /product/ 

    static contextType = SiteContext;

    constructor(props) {
        super(props);
        this.state = {
            searchResults: [],
            showSearch: false,
            showMenu: false,
        }
    }

    componentDidMount() {
        // console.log('tools props', this.props)
    }

    componentDidUpdate() {
        // console.log('tools props', this.props)
    }

    setResults = results => {
        this.props.setResults({
            searchResults: results,
        });
    }

    toggleSearchBox = () => {
        this.setState({
            showSearch: !this.state.showSearch,
            showMenu: false,
        });
    }

    // toggleMenu = () => {
    //     this.setState({
    //         showMenu: !this.state.showMenu,
    //         showSearch: false,
    //     });
    // }

    renderSearchBox = () => {
        return (
            <div className="search-field-wrapper">
                <TextField label="Search (Product name, system name)" style={styles.searchBox} variant="filled" onKeyDown={(e) => this._handleKeyDown(e)} />
            </div>
        )
    }

    // renderMenu = () => {
    //     return (
    //         <div style={styles.menu}>
    //             <a className={`link-row ${this.props.site}`} href={`/${this.props.site}`}>Home <span className={`icon ${this.props.site} right`}><IcomoonReact iconSet={iconSet} size={20} icon="arrow-right2" /></span></a>
    //             <a className={`link-row ${this.props.site}`} href={`/${this.props.site}/systems`}>Systems <span className={`icon ${this.props.site} right`}><IcomoonReact iconSet={iconSet} size={20} icon="arrow-right2" /></span></a>
    //             <a className={`link-row ${this.props.site}`} href={`/${this.props.site}/products`}>Products <span className={`icon ${this.props.site} right`}><IcomoonReact iconSet={iconSet} size={20} icon="arrow-right2" /></span></a>
    //             <a className={`link-row ${this.props.site}`} href={`/${this.props.site}/videos`}>Videos <span className={`icon ${this.props.site} right`}><IcomoonReact iconSet={iconSet} size={20} icon="arrow-right2" /></span></a>
    //             <a className={`link-row ${this.props.site}`} href={`/${this.props.site}/literature`}>Literature Library <span className={`icon ${this.props.site} right`}><IcomoonReact iconSet={iconSet} size={20} icon="arrow-right2" /></span></a>
    //             <a className={`link-row ${this.props.site}`} href={`/${this.props.site}/contact`}>Contact Us <span className={`icon ${this.props.site} right`}><IcomoonReact iconSet={iconSet} size={20} icon="arrow-right2" /></span></a>
    //             <p className="disclaimer-wrapper" dangerouslySetInnerHTML={{ __html: this.state.disclaimer }}></p>
    //         </div>
    //     )
    // }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            var searchTerm = e.target.value.toLowerCase();
            navigate(`/search?query=${searchTerm}`)
        }
    }

    render() {
        return (
            <SiteContext.Consumer>
                {siteContext => (
                    <div className="header-tools">
                        <a href='/contact'><IcomoonReact iconSet={iconSet} size={30} icon="contact" /></a>
                        <span className={`search-button ${this.state.showSearch ? 'search-button--active' : ''}`} onClick={this.toggleSearchBox}>  <IcomoonReact iconSet={iconSet} size={30} icon="search" /></span>
                        {/* <span onClick={this.toggleMenu}><IcomoonReact iconSet={iconSet} color="white" size={20} icon="menu" /></span> */}
                        {this.state.showSearch ? this.renderSearchBox() : null}
                        {/* {this.state.showMenu ? this.renderMenu() : null} */}
                    </div>
                )}
            </SiteContext.Consumer>
        );
    }
}


export default HeaderTools