import React, { Component } from 'react'
import { navigate } from "@reach/router"

import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel
} from '@material-ui/core'

class Footer extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            language: '',
            region: '',
            languageText: '',
            selectedLanguageOption: '',
            selectedRegionOption: '',
            regionOptions: [],
            showRegionPicker: false,
            languageCode: '',
        }
    }

    componentDidMount() {
        const { locale } = this.context
    }

    render() {
        return (
            <div className="footer" id="footer">
                
            </div>
        )
    }
}

export default Footer