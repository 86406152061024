import { Link, graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import Img from 'gatsby-image';

import HeaderTools from './HeaderTools'

import senergyLogo from '../images/senergy-logo.svg'
import SiteContext from "../context/SiteContext"
import iconSet from '../../site_icons/selection.json'
import IcomoonReact, { iconList } from 'icomoon-react'
import AniLink from "gatsby-plugin-transition-link/AniLink"

class Nav extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            headerColor : 'light',
            hasBackBtn : false,
            backTitle : '',
            backUrl : '',
            hasLogo : true,
            fromURL : '',
            showHeader : true,
            logo: null,
            site: '',
            base: '',
        }
    }

    componentDidMount(){
        //Get url 
        let url = typeof window !== 'undefined' ? window.location.pathname : null;

        if (url !== null){

            let site = 'senergy'
            
            this.setState({ site })
            //Get segments of the url and find how many of them 
            let segments = url.split('/').filter( x => x !== '' )
            let depth = segments.length;

            // console.log("segments : ", segments );
            // console.log("depth : ", depth );

            //on staging, /senergy/ is a segment. Remove this and add it as a variable. 
            let multiSiteBase = '';
            if (segments[0] === 'senergy') {
                multiSiteBase = '/' + segments[0];
                segments.shift();
            } 

            console.log('multisite base', multiSiteBase)

            //Find the base url 
            let base = segments[0];

            console.log('base', base)

            //Check for home
            if (base === '' || base === undefined || base === 'welcome'){
                base = "home";
            }

            this.setState({ base })

            // console.log("base page : ", base );
    
            //Set the top header color based on the page
            if ( base === 'systems' && segments[1] === undefined 
                || base === 'products' && segments[1] === undefined 
                || base === 'system'
                || base === 'contact' 
                || base === 'literature'
                || base === 'videos'
                || base === 'search'
                || base === 'thank-you'
                ) {
                    this.setState({ headerColor : 'color'})
            }  

            //Check for 'from' parameter in the URL, used on product and system pages to tell where to go back to
            let fromURL, fromTitle = '';
            if (typeof window !== "undefined"){
                const urlParams = new URLSearchParams(window.location.search);
                fromURL = urlParams.get('fromUrl');
                fromTitle = urlParams.get('fromTitle');
            }

            // Build the back buttons
            if (base !== 'home') {

                let backTitle = base;
                let backUrl = multiSiteBase; //add 'senergy' or 'finestone' url base if needed, on staging

                if (base === 'product') {
                    if (fromTitle && fromURL) {
                        backTitle = fromTitle;
                        backUrl = backUrl + "/products/" + fromURL;
                    } else {
                        backTitle = "Products";
                        backUrl = backUrl + "/products/";
                    }
                } 
                else if (base === 'system') {
                    if (fromTitle && fromURL) {
                        backTitle = fromTitle;
                        backUrl = backUrl + "/systems/" + fromURL;
                    } else {
                        backTitle = "Systems";
                        backUrl = backUrl + "/systems/";
                    }
                }
                else if (base === 'products' && segments[1]) {
                    backTitle = "Products";
                    backUrl = backUrl + "/products/";
                }
                else if (base === 'systems' && segments[1]){
                    backTitle = 'Systems';  
                    backUrl = backUrl + "/systems/";
                }
                else {
                    backTitle = "Home";
                    if (multiSiteBase !== ''){
                        backUrl = backUrl + '/welcome'
                    }
                    else {
                        backUrl = '/welcome'
                    }
                  
                }
                            
                this.setState({ hasBackBtn : true, hasLogo:false, backTitle : backTitle, backUrl : backUrl })
            } 

        }
        else {
            console.warn("cannot find page url");
        }
    }

    render() {
        return (
            <SiteContext.Consumer>
                {siteContext => (
                    <header>
                        { this.state.showHeader 
                            ?
                            <div className={`app-nav senergy app-nav--${this.state.headerColor}`}> 
                                { this.state.hasBackBtn 
                                    ? 
                                    <AniLink swipe direction="right" top="entry" duration={.2} to={this.state.backUrl} className="app-nav__back-btn">
                                        <span className="app-nav__back-icon"> <IcomoonReact iconSet={iconSet} size={30} icon="arrow-left" /> </span>
                                        <span className="app-nav__back-text"> {this.state.backTitle} </span>    
                                    </AniLink>
                                    : null
                                }
                                { this.state.hasLogo && (this.state.base === 'home' || this.state.base === 'welcome')
                                    ?
                                    <div className="app-nav__logo">
                                        <a href='/welcome'>
                                            <img src={senergyLogo} />
                                        </a>
                                    </div>
                                    : null
                                }
                                <HeaderTools site="senergy" />
                            </div>
                            : null
                        }
                    </header>
                )}
            </SiteContext.Consumer>
        )
    }
}

export default Nav